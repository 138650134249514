
import { Bar } from 'vue-chartjs'
import Vue from 'vue'
import { mapGetters } from 'vuex';

export default Vue.extend({
    name: 'barChart',
    extends: Bar,
    props: {chartdata: {type:Object}},
    mounted(this:any){
        if(!this.isTesting)
            this.renderChart(this.chartdata.data,this.chartdata.options)
    },
    watch: {
        //FIXME: set targetMonthly & cac, margin% = -infinity
        //TODO: Save instead of rendering new data if not visible, render when visible switched
        chartdata: {
            handler(this:any,n){ 
                if(!this.isTesting)
                    this.renderChart(n.data,n.options) 
            },
            deep: true
        }
    },
    computed: {
        ...mapGetters(['isTesting'])
    }
})

